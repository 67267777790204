






import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableItemWrap',

  props: {
    defaultValue: {
      type: String,
      default: '---',
    },

    value: {
      type: [String, Number],
      default: undefined,
    },
  },
});
