








































































































































































































import { mdiApplicationImport, mdiMagnify, mdiPlusCircle, mdiMinusCircle } from '@mdi/js';
import _ from 'lodash';
import { ulid } from 'ulid';
import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import DataTable from '@/components/generic/DataTable.vue';
import DataTableItemBasicStatus from '@/components/generic/DataTableItemBasicStatus.vue';
import DataTableItemWrap from '@/components/generic/DataTableItemWrap.vue';
import DialReplaceList from '@/components/generic/DialReplaceList.vue';
import PageTitle from '@/components/specific/PageTitle.vue';
import {
  MESSAGE_CALL_REQUEST_DONE,
  MESSAGE_FLOW_NOT_SELECTED,
  PARTNER_STATUSES,
} from '@/resources/defines';
import stripeTimestampToDate from '@/resources/functions/stripeTimestampToDate';
import PartnerService from '@/services/ui/PartnerService';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { DomainPartnerMapper } from '@/store/modules/domain/partner';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIDialMapper } from '@/store/modules/ui/dial';
import type { ReplaceItem } from '@/store/modules/ui/dial';
import type { DataTableHeader } from 'vuetify';

const DialService = ServiceFactory.get('dial');
const IvrFlowService = ServiceFactory.get('ivrFlow');
const InvoiceService = ServiceFactory.get('invoice');

const curlSampleParameter = `  -H 'Content-Type: application/json' \\
  -H 'Authorization: Bearer {APIキー}' \\
  -d '{ "phone": "{発信先電話番号}" }'`;

type FlowItem = { FlowId: string; Name: string; Status: number };

export default Vue.extend({
  name: 'MainPage',

  components: {
    DataTable,
    DataTableItemBasicStatus,
    DataTableItemWrap,
    DialReplaceList,
    PageTitle,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    callInfo: {
      period: string;
      limit: number | undefined;
      count: number | undefined;
      remain: number | undefined;
    };
    closeDialog: {
      manual: boolean;
    };
    flowTable: {
      footerProps: {
        itemsPerPageOptions: number[];
        showCurrentPage: boolean;
        showFirstLastPage: boolean;
      };
      headers: DataTableHeader[];
      itemKey: string;
      items: FlowItem[];
      loading: boolean;
    };
    dialParams: {
      phoneNum: string;
      replaceItems: ReplaceItem[];
    };
    icons: {
      [key: string]: string;
    };
    loading: {
      manual: boolean;
    };
    selectedFlowId: string | undefined;
    search: string;
    showedDialog: {
      [key: string]: boolean;
    };
    invoiceLoading: boolean;
    invoiceUpcoming: number | undefined;
    upcomingTitle: string;
  } {
    return {
      callInfo: {
        count: undefined,
        limit: undefined,
        period: '',
        remain: undefined,
      },
      closeDialog: {
        manual: true,
      },
      dialParams: {
        phoneNum: '',
        replaceItems: [],
      },
      flowTable: {
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showCurrentPage: true,
          showFirstLastPage: true,
        },
        headers: [
          {
            sortable: true,
            text: 'フロー名称',
            value: 'Name',
          },
          {
            filterable: false,
            sortable: false,
            text: 'ステータス',
            value: 'Status',
          },
          {
            filterable: false,
            sortable: false,
            text: 'トリガー',
            value: 'FlowId',
          },
        ],
        itemKey: 'FlowId',
        items: [],
        loading: false,
      },
      icons: {
        mdiApplicationImport,
        mdiMagnify,
        mdiMinusCircle,
        mdiPlusCircle,
      },
      invoiceLoading: true,
      invoiceUpcoming: undefined,
      loading: {
        manual: false,
      },
      search: '',
      selectedFlowId: undefined,
      showedDialog: {
        manual: false,
        trigger: false,
        webhook: false,
      },
      upcomingTitle: '請求予定額',
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...DomainPartnerMapper.mapState({ partnerStatus: 'status' }),
    ...UIDialMapper.mapGetters(['closeDialogAfterDial', 'replaceItems']),

    curlSample(): string {
      return `curl ${this.webhookUrl} \\\n${curlSampleParameter}`;
    },
    disabledDial(): boolean {
      const enabledStatuses = _.filter(PARTNER_STATUSES, ['dialEnabled', true]);
      const enabledValues = _.mapValues(enabledStatuses, 'value');

      return !_.includes(enabledValues, this.partnerStatus);
    },
    displayedCallInfoCount(): string {
      if (this.callInfo.count === undefined) {
        return '';
      }
      return this.callInfo.count.toLocaleString();
    },
    displayedCallInfoLimit(): string {
      if (this.callInfo.limit === undefined) {
        return '';
      }
      if (this.callInfo.limit === 0) {
        return '無制限';
      }
      return this.callInfo.limit.toLocaleString();
    },
    displayedCallInfoRemain(): string {
      if (this.callInfo.limit === undefined || this.callInfo.count === undefined) {
        return '';
      }
      if (this.callInfo.limit === 0) {
        return '無制限';
      }
      return (this.callInfo.limit - this.callInfo.count).toLocaleString();
    },
    formattedInvoiceUpcoming(): string {
      if (this.invoiceUpcoming === undefined) {
        return '';
      }
      return this.invoiceUpcoming.toLocaleString();
    },
    webhookUrl(): string {
      const self = this;
      const { interfaceAPIEndpoint } = settings;

      return `${interfaceAPIEndpoint}dial/${self.userAttributes.partner_id}/${self.selectedFlowId}`;
    },
  },

  created() {
    this.getCallInfo();
    this.getInvoiceUpcoming();
    this.getIvrFlows();
  },

  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    ...UIDialMapper.mapActions(['setReplaceItems', 'setCloseDialogAfterDial']),

    // 置き換えリストの要素を追加する
    addItem() {
      this.dialParams.replaceItems.push({
        key: ulid(),
        readingText: '',
        replacement: '',
      });
    },

    // 手動発報する
    async dialManually() {
      const self = this;

      self.$v.dialParams.$touch();

      if (self.$v.dialParams.$invalid) {
        self.$$log.debug(self.$v.dialParams);
        return;
      }

      // 発報
      try {
        if (self.selectedFlowId === undefined) {
          throw new Error(MESSAGE_FLOW_NOT_SELECTED);
        }

        self.setReplaceItems({
          flowId: self.selectedFlowId,
          partnerId: self.userAttributes.partner_id,
          replaceItems: self.dialParams.replaceItems,
        });

        const paramArgs = {};
        _.forEach(self.dialParams.replaceItems, (item) => {
          _.set(paramArgs, item.replacement, item.readingText);
        });

        self.loading.manual = true;

        await DialService.dial(self.selectedFlowId, {
          args: paramArgs,
          phone: self.dialParams.phoneNum,
        });

        self.loading.manual = false;

        self.setCloseDialogAfterDial({
          close: self.closeDialog.manual,
          partnerId: self.userAttributes.partner_id,
        });

        if (self.closeDialog.manual) {
          self.showedDialog.manual = false;
        }

        self.setMessage({
          color: 'success',
          text: MESSAGE_CALL_REQUEST_DONE,
        });
      } catch (error) {
        self.loading.manual = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    // 有効なフローかどうか
    disabledFlow(status: number) {
      return status !== 1;
    },

    // APIによりany型が定義されている
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter(value: any, search: string | null) {
      if (_.isNil(search)) {
        return true;
      }
      if (typeof value === 'string' && value.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    },

    // 今月の通知量
    async getCallInfo() {
      const self = this;

      try {
        const response = await PartnerService.getCallInfo();

        self.callInfo = response;
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    // 今月の請求予定額
    async getInvoiceUpcoming() {
      const self = this;

      try {
        const response = await InvoiceService.getInvoiceUpcoming();

        self.invoiceLoading = false;
        self.invoiceUpcoming = response.total;
        const periodStart = self
          .$$dayjs(stripeTimestampToDate(response.period_start))
          .format('YYYY年M月分');
        self.upcomingTitle = `請求予定額（${periodStart}）`;
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    // 通知フロー設定
    async getIvrFlows() {
      const self = this;

      try {
        const response = await IvrFlowService.getIvrFlows({
          fields: ['FlowId', 'Name', 'Status'],
        });

        _.map(response, (item: FlowItem) => {
          self.flowTable.items.push({
            FlowId: item.FlowId,
            Name: item.Name,
            Status: item.Status,
          });
        });
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },

    // 読み上げテキスト項目が入力された際、リストの要素に反映する
    handleReadingText(value: string, index: number) {
      this.$v.dialParams.replaceItems.$each.$iter[index].readingText.$model = value;
    },

    // 置き換え項目が入力された際、リストの要素に反映する
    handleReplacement(value: string, index: number) {
      this.$v.dialParams.replaceItems.$each.$iter[index].replacement.$model = value;
    },

    // 置き換えリストの要素を削除する
    removeItem(index: number) {
      this.dialParams.replaceItems.splice(index, 1);
    },

    // 手動発報モーダルを開く
    showDialogManual() {
      const self = this;

      if (self.selectedFlowId !== undefined) {
        self.dialParams.phoneNum = '';
        self.dialParams.replaceItems = self.replaceItems(
          self.userAttributes.partner_id,
          self.selectedFlowId
        );
        self.closeDialog.manual = self.closeDialogAfterDial(self.userAttributes.partner_id);
      }

      self.$v.dialParams.$reset();
      self.showedDialog.trigger = false;
      self.showedDialog.manual = true;
    },

    // トリガーモーダルを開く
    showDialogTrigger(item: FlowItem) {
      const enabledStatuses = _.filter(PARTNER_STATUSES, ['dialEnabled', true]);
      const enabledValues = _.mapValues(enabledStatuses, 'value');

      if (!_.includes(enabledValues, this.partnerStatus)) {
        return;
      }

      this.selectedFlowId = item.FlowId;
      this.showedDialog.trigger = true;
    },

    // Webhookモーダルを開く
    showDialogWebhook() {
      this.showedDialog.trigger = false;
      this.showedDialog.webhook = true;
    },
  },
  validations() {
    return {
      dialParams: {
        phoneNum: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          forwardExtPhone: this.$$validators.forwardExtPhone,
          notStartWith: this.$$validators.notStartWith([
            '0120',
            '0800',
            '0570',
            '0037',
            '0066',
            '0078',
          ]),
          /* eslint-enable vue/sort-keys */
        },
        replaceItems: {
          $each: {
            $trackBy: 'key',
            readingText: {
              // エラーメッセージを表示したい順序で定義する
              /* eslint-disable vue/sort-keys */
              required,
              maxLength: maxLength(255),
              /* eslint-enable vue/sort-keys */
            },
            replacement: {
              // エラーメッセージを表示したい順序で定義する
              /* eslint-disable vue/sort-keys */
              required,
              dialReplacement: this.$$validators.dialReplacement,
              maxLength: maxLength(24),
              /* eslint-enable vue/sort-keys */
            },
          },
        },
      },
    };
  },
});
