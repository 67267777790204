







































import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js';
import Vue from 'vue';
import type { ReplaceItem } from '@/store/modules/ui/dial';

// 置き換え文字列アイテム最大数
const MaxItemCount = 20;

export default Vue.extend({
  name: 'DialReplaceList',

  props: {
    // リスト項目の一覧
    items: {
      type: Array as Vue.PropType<ReplaceItem[]>,
      required: true,
    },
    // リスト要素に対するバリデーション
    validations: {
      type: Object,
      required: true,
    },
  },

  data(): {
    icons: {
      [key: string]: string;
    };
  } {
    return {
      icons: {
        mdiMinusCircle,
        mdiPlusCircle,
      },
    };
  },

  computed: {
    // 置き換え文字列アイテムを追加可能かどうか
    addableItem() {
      return this.items.length < MaxItemCount;
    },
  },

  methods: {
    // 追加ボタンが押されたことを親に通知する
    addItem() {
      this.$emit('add-item');
    },

    // 読み上げテキストが入力された際、その値を親に通知する
    handleInputReadingText(value: string, index: number) {
      this.$emit('reading-text-input', value, index);
    },

    // 置き換え文字列が入力された際、その値を親に通知する
    handleInputReplacement(value: string, index: number) {
      this.$emit('replacement-input', value, index);
    },

    // 削除ボタンが押されたことを親に通知する
    removeItem(index: number) {
      this.$emit('remove-item', index);
    },
  },
});
