
















import Vue from 'vue';
import { BASIC_STATUSES } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemBasicStatus',

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      basicStatuses: BASIC_STATUSES,
    };
  },
});
